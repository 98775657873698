import {z} from 'zod';

import {useTranslations} from '@/hooks/useTranslations';
import {BaseInput} from '@/components/shared/validatedForm/legacy/BaseInput';
import {type BaseInputProps} from '@/components/shared/BaseInput/BaseInput';

type CompanyProps = {
  inputProps?: Omit<BaseInputProps | 'id', 'type'>;
};

export const companyValidator = {
  company: z.string().min(1, {message: 'forms:lead.company.error'}),
};

export default function CompanyTextInput({inputProps}: CompanyProps) {
  const {t} = useTranslations();
  return (
    <BaseInput
      label={t('forms:lead.company.label')}
      placeholder={t('forms:lead.company.label')}
      {...inputProps}
      id="company"
      type="text"
    />
  );
}
