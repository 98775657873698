import {camelCaseToSnakeCaseAllProps, useDuxState} from '@shopify/dux';
import {useEffect, useRef, useState} from 'react';
import {useActionData} from '@remix-run/react';

import type {HandleFormSubmitOptions} from '@/hooks/useDuxTracking';
import {useFormTracking} from '@/hooks/useDuxTracking';
import type {
  GTMProperties,
  AttributionProps,
} from '@/components/shared/BaseLeadForm/types';
import {ApprovedCookie} from '@/enums';
import {getCookie} from '@/utils/utils';
import {useBrowserUrl} from '@/hooks/useBrowserUrl';
import {useSiteData} from '@/hooks/useSiteData';
import useDataLayer from '@/hooks/useDataLayer';

export type UseFormTrackingPropsType = {
  attributionProps: AttributionProps;
  /**
   * Use only if action data is accessed from a different route.
   * Otherwise `useActionData` handles the form submission state internally
   */
  submitResponse?: string;
  gtmProperties?: GTMProperties;
  onFormSubmitSuccess?: () => void;
};

interface TrackingProperties extends AttributionProps {
  experimentVariationId?: string;
  pageViewToken: string;
  userToken: string;
  sessionToken: string;
  signUpPage: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
  utmSource: string;
  utmTerm: string;
  gclid: string;
}

export function useFormAttributionTracking({
  attributionProps,
  submitResponse,
  gtmProperties,
  onFormSubmitSuccess,
}: UseFormTrackingPropsType) {
  const actionData = useActionData<any>();
  const {canonicalUrl} = useSiteData();
  const [trackingProperties, setTrackingProperties] =
    useState<TrackingProperties | null>(null);
  const currentURL = useBrowserUrl();
  const [lastStatus, setLastStatus] = useState('');
  const submittedData = useRef({} as any);
  const dataLayer = useDataLayer();
  function successTracker({trackers}: HandleFormSubmitOptions): void {
    let successEventTracking = {
      event_action: 'success',
      event_label: 'submitted',
      event: 'form_success',
    };

    /* eslint-disable camelcase */
    const eventData = {
      ...camelCaseToSnakeCaseAllProps(submittedData.current),
      // These two properties can be overridden by the props, so they're placed higher
      primary_product: trackingProperties?.primaryProduct,
      source: trackingProperties?.source,
      form_type: trackingProperties?.formType,
      ...camelCaseToSnakeCaseAllProps(gtmProperties),
      page_view_token: trackingProperties?.pageViewToken,
      gclid: trackingProperties?.gclid,
    } as {[key: string]: string};
    /* eslint-enable camelcase */

    if (trackers?.gtm) {
      trackers.gtm(successEventTracking, eventData);
    }
  }
  function errorTracker({trackers}: HandleFormSubmitOptions): void {
    const errorEventTracking = {
      eventAction: 'error',
      eventLabel: 'validation failed',
      event: 'form_error',
    };

    if (trackers?.gtm) {
      trackers.gtm(errorEventTracking, {});
    }
  }

  const trackSuccessEvent = useFormTracking(successTracker);
  const trackErrorEvent = useFormTracking(errorTracker);

  const {pageViewToken} = useDuxState();
  useEffect(() => {
    setTrackingProperties({
      ...attributionProps,
      experimentVariationId: dataLayer.experimentVariationId,
      pageViewToken,
      userToken: getCookie(ApprovedCookie.MultiTrackToken),
      sessionToken: getCookie(ApprovedCookie.SessionToken),
      signUpPage: canonicalUrl ?? '',
      utmMedium: encodeURIComponent(
        currentURL?.searchParams.get('utm_medium') || '',
      ),
      utmCampaign: encodeURIComponent(
        currentURL?.searchParams.get('utm_campaign') || '',
      ),
      utmContent: encodeURIComponent(
        currentURL?.searchParams.get('utm_content') || '',
      ),
      utmSource: encodeURIComponent(
        currentURL?.searchParams.get('utm_source') || '',
      ),
      utmTerm: encodeURIComponent(
        currentURL?.searchParams.get('utm_term') || '',
      ),
      gclid: encodeURIComponent(currentURL?.searchParams.get('gclid') || ''),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageViewToken]);

  // Effect handles a successful clientside validation and post to the server. This is not run on a client side validation failure.
  useEffect(() => {
    if (actionData && lastStatus !== actionData.status) {
      setLastStatus(actionData.status);
    } else if (submitResponse) {
      setLastStatus(submitResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, submitResponse]);

  useEffect(() => {
    if (lastStatus === 'success') {
      trackSuccessEvent();
      onFormSubmitSuccess?.();
    } else if (lastStatus === 'error') {
      trackErrorEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStatus]);

  function submitHandler(data: any) {
    submittedData.current = data;
    setLastStatus('');
  }
  return {submitHandler, trackingProperties};
}
