import {z} from 'zod';

import {useTranslations} from '@/hooks/useTranslations';
import {BaseInput} from '@/components/shared/validatedForm/legacy/BaseInput';
import {type BaseInputProps} from '@/components/shared/BaseInput/BaseInput';

type PhoneProps = {
  inputProps?: Omit<BaseInputProps | 'id', 'type'>;
};

export const phoneValidator = {
  phone: z.string().min(1, {message: 'forms:lead.phone.error'}),
};

export default function PhoneTextInput({inputProps}: PhoneProps) {
  const {t} = useTranslations();
  return (
    <BaseInput
      placeholder={t('forms:lead.phone.label')}
      label={t('forms:lead.phone.label')}
      {...inputProps}
      id="phone"
      type="tel"
    />
  );
}
