import {useFetcher} from '@remix-run/react';
import {useViewability} from '@shopify/dux';
import {ValidatedForm} from 'remix-validated-form';

import FormButton from '@/components/base/elements/FormButton/FormButton';
import {ModalColorScheme} from '@/components/shared/Modal/Modal';
import {twMerge} from '@/stylesheets/twMerge';
import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';

import DisclaimerCheckbox from '../LeadFormFields/DisclaimerCheckbox/DisclaimerCheckbox';

import {useFormAttributionTracking} from './hooks/useFormAttributionTracking';
import type {ActionResult, AttributionProps, GTMProperties} from './types';
import {type BaseLeadFormValidator} from './server/buildLeadFormPayload.server';

interface BaseLeadFormProps {
  formValidator: BaseLeadFormValidator;
  children: React.ReactNode;
  colorScheme?: ModalColorScheme;
  formSubmissionEndpoint?: string;
  attributionProps: AttributionProps;
  onFormSubmitSuccess: (data: ActionResult) => void;
  className?: string;
  redirectUrl?: string;
  gtmProperties?: GTMProperties;
  content?: {
    marketingCommunicationConsentLabel?: string;
    submitButtonText?: string;
  };
  marketingCommunicationConsent?: 'Explicit' | 'Implicit';
}

function isValidActionResponse(fetcher: any): fetcher is {data: ActionResult} {
  return (
    typeof fetcher?.data === 'undefined' ||
    fetcher?.data?.status === 'success' ||
    fetcher?.data?.status === 'error'
  );
}

export default function BaseLeadForm({
  colorScheme = ModalColorScheme.Light,
  attributionProps,
  formSubmissionEndpoint,
  formValidator,
  gtmProperties,
  children,
  onFormSubmitSuccess,
  redirectUrl,
  className = '',
  content,
  marketingCommunicationConsent = 'Explicit',
}: BaseLeadFormProps) {
  const {t} = useTranslations();
  const [viewableRef] = useViewability();

  const fetcher = useFetcher<ActionResult>();
  if (!isValidActionResponse(fetcher)) {
    throw new Error(
      'BaseLeadForm action data is invalid, ensure your action returns a valid status prop',
    );
  }
  const submitResponse = fetcher.data?.status;
  const isSubmitting = fetcher.state !== 'idle';

  function formSuccessHandler() {
    if (onFormSubmitSuccess) {
      if (isValidActionResponse(fetcher)) {
        onFormSubmitSuccess(fetcher.data);
      }
    } else if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  const {submitHandler, trackingProperties} = useFormAttributionTracking({
    attributionProps,
    submitResponse,
    gtmProperties,
    onFormSubmitSuccess: formSuccessHandler,
  });

  return (
    <section
      className={twMerge('pb-36', className)}
      data-component-name="lead-registration-form"
      data-component-extra-form-type={trackingProperties?.formType}
      data-component-extra-source={trackingProperties?.source}
      data-component-extra-primary-product={trackingProperties?.primaryProduct}
      data-component-extra-sign-up-page={trackingProperties?.signUpPage}
      // wait for trackingProperties to settle
      ref={trackingProperties?.formType ? viewableRef : undefined}
    >
      <ValidatedForm
        validator={formValidator}
        method="post"
        acceptCharset="UTF-8"
        onSubmit={submitHandler}
        fetcher={fetcher}
        action={formSubmissionEndpoint}
        noValidate
      >
        {children}
        {trackingProperties &&
          Object.entries(trackingProperties).map(([key, value]) => (
            <input type="hidden" name={key} key={key} value={value} />
          ))}
        {marketingCommunicationConsent === 'Explicit' && (
          <DisclaimerCheckbox
            mode={colorScheme}
            label={
              content?.marketingCommunicationConsentLabel ||
              t('forms:lead.marketingCommunicationConsent.label')
            }
          />
        )}
        <div className="flex flex-end">
          <FormButton
            type="submit"
            className="grow md:grow-0 mt-sm"
            componentName="registration-form-submit"
            mode={colorScheme}
            aria-describedby="registration-disclaimerText"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? t('forms:submit:label_submitting')
              : content?.submitButtonText || t('forms:submit:label')}
          </FormButton>
        </div>
        {marketingCommunicationConsent === 'Implicit' && (
          <>
            <input
              type="hidden"
              name="marketing_communication_consent"
              value="Implicit"
            />
            <Typography className="text-sm mt-sm">
              {content?.marketingCommunicationConsentLabel ||
                t('forms:lead.marketingCommunicationConsent.disclaimer')}
            </Typography>
          </>
        )}
      </ValidatedForm>
    </section>
  );
}
