import {z} from 'zod';

import {Select as SelectLegacy} from '@/components/shared/validatedForm/legacy/Select';
import {Select} from '@/components/shared/validatedForm/Select';
import {type SelectProps} from '@/components/shared/Select/Select';
import {useTranslations} from '@/hooks/useTranslations';
import {useFormatCurrency} from '@/utils/string/formatCurrency';

import {RevenueValues} from './constants';
import {useRevenueRanges} from './hooks/useRevenueRanges';

type Props = {
  selectProps?: Omit<SelectProps | 'id', 'options'>;
  legacyField?: boolean;
  type: 'online' | 'offline';
  exp?: boolean; // used for free trial exp
  setFreeTrialExperimentSelectValue?: any; // used for free trial exp
};

export const annualOfflineRevenueRangeValidation = {
  annualOfflineRevenueRange: z.nativeEnum(RevenueValues, {
    message: 'forms:lead.annualOfflineRevenueRange.error',
  }),
};

export const annualOnlineRevenueRangeValidation = {
  annualRevenueRange: z.nativeEnum(RevenueValues, {
    message: 'forms:lead.annualOnlineRevenueRange.error',
  }),
};

export default function AnnualRevenueRangeSelect({
  selectProps,
  legacyField,
  type,
  exp,
  setFreeTrialExperimentSelectValue,
}: Props) {
  const {t} = useTranslations();
  let revenueRanges = useRevenueRanges();
  const formatCurrency = useFormatCurrency();
  const currencyFormat = (value: number) =>
    formatCurrency(value, {minimumSignificantDigits: 1});

  const handleSelectChange = (event: any) => {
    if (exp && setFreeTrialExperimentSelectValue) {
      setFreeTrialExperimentSelectValue(
        event.target.selectedOptions[0].getAttribute('data-label'),
      );
    }
  };

  // used for free trial exp. don't remove
  if (exp) {
    const between2and5 = {
      label: `${currencyFormat(2000000)} - ${currencyFormat(5000000)}`,
      value: RevenueValues.BETWEEN_2M_10M,
    };
    const between5and10 = {
      label: `${currencyFormat(5000000)} - ${currencyFormat(10000000)}`,
      value: RevenueValues.BETWEEN_2M_10M,
    };
    revenueRanges.splice(4, 1);
    revenueRanges.splice(4, 0, between2and5, between5and10);
  }

  let translationKey, name;

  switch (type) {
    case 'online':
      translationKey = 'annualOnlineRevenueRange';
      name = 'annualRevenueRange';
      break;
    case 'offline':
      translationKey = 'annualOfflineRevenueRange';
      name = 'annualOfflineRevenueRange';
      break;
    default:
      throw new Error('Invalid type');
  }

  return legacyField ? (
    <SelectLegacy
      label={t(`forms:lead.${translationKey}.label`)}
      divider
      {...selectProps}
      id={name}
      name={name}
      options={revenueRanges}
      onChange={handleSelectChange}
    />
  ) : (
    <Select
      label={t(`forms:lead.${translationKey}.label`)}
      required
      {...selectProps}
      id={name}
      name={name}
    >
      {revenueRanges.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
