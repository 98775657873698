import {z} from 'zod';

import {useTranslations} from '@/hooks/useTranslations';
import {BaseInput} from '@/components/shared/validatedForm/legacy/BaseInput';
import {type BaseInputProps} from '@/components/shared/BaseInput/BaseInput';

type NameProps = {
  inputProps?: Omit<BaseInputProps | 'id', 'type'>;
};

export const nameValidator = {
  name: z.string().min(1, {message: 'forms:lead.name.error'}),
};

export default function NameTextInput({inputProps}: NameProps) {
  const {t} = useTranslations();
  return (
    <BaseInput
      placeholder={t('forms:lead.name.label')}
      label={t('forms:lead.name.label')}
      {...inputProps}
      id="name"
      type="text"
    />
  );
}
