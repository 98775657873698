import {z} from 'zod';

import {useTranslations} from '@/hooks/useTranslations';
import {BaseInput} from '@/components/shared/validatedForm/legacy/BaseInput';
import {type BaseInputProps} from '@/components/shared/BaseInput/BaseInput';

type EmailProps = {
  inputProps?: Omit<BaseInputProps | 'id', 'type'>;
};

export const emailValidator = {
  email: z
    .string()
    .min(1, {message: 'forms:lead.email.error'})
    .email('forms:lead.email.error'),
};

export default function EmailTextInput({inputProps}: EmailProps) {
  const {t} = useTranslations();
  return (
    <BaseInput
      placeholder={t('forms:lead.email.label')}
      label={t('forms:lead.email.label')}
      {...inputProps}
      id="email"
      type="email"
    />
  );
}
